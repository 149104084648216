import { Injectable } from "@angular/core";
import { Failure } from "src/app/core/utils/failure";
import { UseCase } from "src/app/core/utils/usecase";
import { Departament } from "src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component";
import { ProvinceRepositoryBase } from "../repositories/province-base.repository";

@Injectable()
export class GetDepartmentsByConciliadorUseCase implements UseCase<string, Departament[] | Failure> {

    constructor(private provinceRepository: ProvinceRepositoryBase){
        
    }

   async execute(id: string): Promise<Departament[] | Failure> {
        return await this.provinceRepository.getDepartmentsByIdConciliador(id);
   }
}