import { GetFileBaseEntity } from "../../domain/entities/get-file-base.entity";

export class GetFileBaseModel extends GetFileBaseEntity {
    
    override getPath(): string {
        let url: string = '?';
        url += `id=${this.claimId}`
        url += `&file_acta=${this.file_acta}`
        return url;
    }

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.claimId);
        json.set('file_acta', this.file_acta);
        return json;
    }
}