import { Component, OnInit } from '@angular/core';
import { ClaimEntity } from '../../../domain/entities/claim.entity';
import { UpdateClaimUseCase } from '../../../domain/usecases/update-claim.usecase';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { CreateClaimUseCase } from '../../../domain/usecases/create-claim.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimModel } from '../../../data/models/claim.model';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { ClaimUseCase } from '../../../domain/usecases/get-claim.usecase';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class ClaimComponent implements OnInit {
  claimId?: string | null;
  claim?: ClaimEntity;
  activeIndex: number = 0;
  userData?: UserData;
  formComplete: number = 0;
  private AES = new AESEncryptDecryptService();
  isNewClaim: boolean = false;
  userId?: string;


  constructor(private route: ActivatedRoute,
    private updateClaimUseCase: UpdateClaimUseCase,
    private createClaimUseCase: CreateClaimUseCase,
    private claimUseCase: ClaimUseCase,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private localStorageService: LocalStorageServiceBase,
    private myData: GetMyDataUseCase
  ) { }

  ngOnInit(): void {
    this.userId = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));
    this.claimId = this.route.snapshot.paramMap.get('id');
    if(this.router.url.includes('main/new')){
      this.isNewClaim = true;
    }
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.getClaim();
    this.getMyData();
  }

  claimForm: FormGroup = this.formBuilder.group({
    claimetLastname: ['', [Validators.required]],
    claimetName: ['', [Validators.required]],
    claimetCuil: ['', [Validators.required]],
    claimetEmail: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
    claimetPhone: ['', Validators.required],
    claimetProvince: ['', Validators.required],
    claimetCity: ['', [Validators.required]],
    claimetNeighborhood: ['', [Validators.required]],
    claimetStreet: ['', [Validators.required]],
    claimetNumber: ['', [Validators.required]],
    claimetPostalCode: ['', [Validators.required]],
    claimetFloorApartament: [''],
    claimetTower: [''],
    claimetSelectedTypeOfJob: ['', Validators.required],
    claimetWorkday: ['', [Validators.required]],
    claimetGrossRemuneration: ['', [Validators.required]],
    claimetTypeRemuneration: ['', [Validators.required]],
    claimetDateAdmission: [, [Validators.required]],
    claimetEgressDate: [],
    claimetNumberAgreement: ['', [Validators.required]],
    claimetYear: [, [Validators.required]],
    claimetCategory: ['', [Validators.required]],
    claimetCurrentEmploymentStatus: ['', [Validators.required]],
    claimetReasonForDisengagement: [],
    claimetCauseDisengagement: [],
    claimetFileDni: [],
    claimetFilePaycheck: [],
    claimedTypeOfPerson: [, Validators.required],
    claimedNameLastNameOrBusinessName: [, [Validators.required, Validators.maxLength(100)]],
    claimedCuil: [, Validators.required],
    claimedEmail: [, [Validators.required, Validators.email, Validators.maxLength(100)]],
    claimedPhone: [, [Validators.required, Validators.maxLength(10)]],
    claimedStreet: [, [Validators.required, Validators.maxLength(50)]],
    claimedNumber: [, [Validators.required, Validators.maxLength(5)]],
    claimedFloorApartment: [],
    claimedTower: [],
    claimedPostalCode: [, [Validators.required, Validators.maxLength(10)]],
    claimedNeighborhood: [, Validators.required],
    claimedProvince: ['', Validators.required],
    claimedCity: [, Validators.required],
    selecterObjectOfClaim: [, [Validators.required]],
    confirmInformation: [],
    textClaimDetails: ['', [Validators.required]],
    extraDocumentationPdf: [],
    selectedOwnRight: [, [Validators.required]],
    lawyerFullName: ['', [Validators.required]],
    lawyerRegistrationNumber: ['', [Validators.required]],
    lawyerEmail: ['', [Validators.required]],
    user_id: [''],
    id: [''],
    proceedings: [''],
    status: [''],
    created: [''],
    department_number:['']
  });

  async getClaim(): Promise<void> {
    if(!this.claimId) return;
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
  }

  async getMyData(): Promise<void> {
    let result = await this.myData.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
  }

  receiveData(data: FormGroup) {
    this.claimForm.patchValue(data);
    this.formComplete ++;
  }

  onSubmit(data: FormGroup){
    if(this.isNewClaim){
      this.createClaim(data);
    } else {
      this.updateClaim(data);
    }
  }

  activeStep(index: number) {
    this.activeIndex = index
  }

  async createClaim(data: FormGroup): Promise<void> {
    this.claimForm.patchValue(data);
    this.claimForm.get('user_id')?.setValue(this.AES.decrypt(this.localStorageService.loadStorageData('user_id')));
    let result = await this.createClaimUseCase.execute(this.createNewClaim());
    if(result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Reclamo creado',
      detail: 'Su nuevo reclamo se creo exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/home']);
    }, 1100);
  }

  async updateClaim(data: FormGroup): Promise<void> {
    this.claimForm.patchValue(data);
    this.claimForm.get('user_id')?.setValue(this.AES.decrypt(this.localStorageService.loadStorageData('user_id')));
    this.claimForm.get('id')?.setValue(this.claimId);
    this.setFilesToSupportFormat();
    let result = await this.updateClaimUseCase.execute(this.createNewClaim());
    if(result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Reclamo actualizado',
      detail: 'Su reclamo se actualizó exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/home']);
    }, 2000);
  }

  saveDraft() {
    if (this.isNewClaim) {
      this.confirmationService.confirm({
        header: 'Salir del nuevo reclamo',
        message:
          '¿Está seguro de que desea salir? Se perderan los datos cargados.',
        accept: () => {
          this.messageService.add({
            severity: 'contrast',
            summary: 'Cancelado',
            detail: 'Proceso cancelado',
            life: 1100,
          });
          setTimeout(() => {
            this.router.navigate(['/main/claims']);
          }, 1100);
        },
      });
    } else {
      this.confirmationService.confirm({
        header: 'Salir de la edición del reclamo',
        message:
          '¿Está seguro que desea salir? En caso de haber realizado cambios, los mismos se perderán',
        accept: () => {
          setTimeout(() => {
            this.router.navigate(['/main/claims']);
          }, 200);
        },
      });
    }
  }

  setFilesToSupportFormat() {
    let dniList: string[] = [];
    let extraDocList: string[] = [];

    const dniValue = this.claimForm.get('claimetFileDni')?.value;
    if (Array.isArray(dniValue)) {
        dniValue.forEach((item: any) => {
            if (typeof item === 'string') {
                dniList.push(item);
            } else if (item && item.base64) {
                dniList.push(item.base64);
            }
        });
    } else if (dniValue && typeof dniValue === 'object' && dniValue.base64) {
        dniList.push(dniValue.base64);
    }

    const extraDocValue = this.claimForm.get('extraDocumentationPdf')?.value;
    if (Array.isArray(extraDocValue)) {
        extraDocValue.forEach((item: any) => {
            if (typeof item === 'string') {
                extraDocList.push(item);
            } else if (item && item.base64) {
                extraDocList.push(item.base64);
            }
        });
    } else if (extraDocValue && typeof extraDocValue === 'object' && extraDocValue.base64) {
        extraDocList.push(extraDocValue.base64);
    }

    this.claimForm.get('claimetFileDni')?.setValue(dniList);
    this.claimForm.get('extraDocumentationPdf')?.setValue(extraDocList);
}

  createNewClaim(): ClaimModel {
    return new ClaimModel(
      this.claimForm.get('claimetLastname')?.value,
      this.claimForm.get('claimetName')?.value,
      this.claimForm.get('claimetCuil')?.value,
      this.claimForm.get('claimetEmail')?.value,
      this.claimForm.get('claimetPhone')?.value,
      this.claimForm.get('claimetProvince')?.value,
      this.claimForm.get('claimetCity')?.value,
      this.claimForm.get('claimetNeighborhood')?.value,
      this.claimForm.get('claimetStreet')?.value,
      this.claimForm.get('claimetNumber')?.value,
      this.claimForm.get('claimetPostalCode')?.value,
      this.claimForm.get('claimetSelectedTypeOfJob')?.value,
      this.claimForm.get('claimetWorkday')?.value,
      this.claimForm.get('claimetGrossRemuneration')?.value.toString(),
      this.claimForm.get('claimetTypeRemuneration')?.value,
      this.claimForm.get('claimetDateAdmission')?.value,
      this.claimForm.get('claimetNumberAgreement')?.value.toString(),
      this.claimForm.get('claimetYear')?.value,
      this.claimForm.get('claimetCategory')?.value,
      this.claimForm.get('claimetCurrentEmploymentStatus')?.value,
      this.claimForm.get('claimetFileDni')?.value,
      this.claimForm.get('claimetFilePaycheck')?.value,
      this.claimForm.get('claimedTypeOfPerson')?.value,
      this.claimForm.get('claimedNameLastNameOrBusinessName')?.value,
      this.claimForm.get('claimedCuil')?.value.toString(),
      this.claimForm.get('claimedEmail')?.value,
      this.claimForm.get('claimedPhone')?.value.toString(),
      this.claimForm.get('claimedStreet')?.value,
      this.claimForm.get('claimedNumber')?.value.toString(),
      this.claimForm.get('claimedPostalCode')?.value,
      this.claimForm.get('claimedNeighborhood')?.value,
      this.claimForm.get('claimedProvince')?.value,
      this.claimForm.get('claimedCity')?.value,
      this.claimForm.get('selecterObjectOfClaim')?.value,
      this.claimForm.get('textClaimDetails')?.value,
      (this.claimForm.get('extraDocumentationPdf')?.value ?? ''),
      this.claimForm.get('selectedOwnRight')?.value,
      this.claimForm.get('lawyerFullName')?.value,
      this.claimForm.get('lawyerRegistrationNumber')?.value.toString(),
      this.claimForm.get('lawyerEmail')?.value,
      this.claimForm.get('user_id')?.value,
      this.claimForm.get('claimetFloorApartament')?.value,
      this.claimForm.get('claimetTower')?.value,
      (this.claimForm.get('confirmInformation')?.value ?? '').toString(),
      this.claimForm.get('claimetEgressDate')?.value,
      this.claimForm.get('claimetReasonForDisengagement')?.value,
      this.claimForm.get('claimetCauseDisengagement')?.value,
      this.claimForm.get('claimedFloorApartment')?.value,
      this.claimForm.get('claimedTower')?.value,
      this.claimForm.get('id')?.value,
      this.claimForm.get('proceedings')?.value,
      this.claimForm.get('status')?.value,
      this.claimForm.get('created')?.value,
      this.claimForm.get('department_number')?.value
    )
  }

}
