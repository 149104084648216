<div class="">
  <p-toast />
  <p-confirmDialog #cd>
      <ng-template pTemplate="headless" let-message>
          <div class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round ">
              <span class="col-12 font-semibold text-xl ">
                  {{ message.header }}
              </span>
              <div class="col-12 flex align-items-center justify-content-center gap-3">
                  <i class="pi pi-exclamation-circle text-lg"></i>
                  <p class="">{{ message.message }}</p>
              </div>
              <div class="col-12 flex gap-3 justify-content-end">
                  <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar" style="border-color: #263245;
                  background-color: white;
                  color: #263245;
                  font-size: 0.8rem;">
                  </button>
                  <button pButton label="Eliminar usuario" (click)="cd.accept()" class="delete btn-siguiente" style="
                      background-color: #C93B3B;
                      border-color: #C93B3B;
                      font-size: 0.8rem;">
                  </button>
              </div>
          </div>
      </ng-template>
  </p-confirmDialog>
</div>


<section class="m-1 pb-2 sm:mx-6 sm:my-3">
    <div *ngIf="!homeView" class="flex flex-column p-3">
        <p class="font-semibold text-xl title">Usuarios</p>
        <div class="flex grid  w-full align-items-center justify-content-between">
            <div class="busqueda flex col-4">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Buscar" class="inputBuscador" />
                </span>
            </div>
            <div class="col-3 text-right">
                <p-button class="btn-new-claim" label="Nuevo usuario" icon="pi pi-plus"
                    routerLink="/main/createUsers"></p-button>
            </div>
        </div>
    </div>

    <div class="grid w-full relative p-3">
      <p-table [value]="usersList"
      (onLazyLoad)="loadRecords($event)"
      [tableStyle]="{ 'min-width': '100%'}"
      currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}"
      class="col-12"
      [rows]="itemsPerPage"
      [totalRecords]="totalQuantity"
      (onPage)="changePage($event)">
          <ng-template pTemplate="header">
              <tr class="font-semibold text-xs">
                  <th class="col-12 sm:col-6 md:col-3">Apellido y Nombre</th>
                  <th *ngIf="!homeView" class="col-12 sm:col-6 md:col-3">CUIL</th>
                  <th class="col-12 sm:col-6 md:col-3">Correo Electrónico</th>
                  <th class="col-6 sm:col-3 md:col-1">Localidad</th>
                  <th *ngIf="!homeView" class="col-6 sm:col-3 md:col-1">Teléfono</th>
                  <th class="col-12 sm:col-6 md:col-3">Rol</th>
                  <th class="col-12 sm:col-6 md:col-1"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
              <tr class="table-row">
                  <td class="col-12 sm:col-6 md:col-3">{{ user.lastName }}, {{ user.name }}</td>
                  <td *ngIf="!homeView" class="col-12 sm:col-6 md:col-3">{{ user.cuil }}</td>
                  <td class="col-12 sm:col-6 md:col-3">{{ user.email }}</td>
                  <td class="col-6 sm:col-3 md:col-1">{{ user.city }}</td>
                  <td *ngIf="!homeView" class="col-6 sm:col-3 md:col-1">{{ user.phone }}</td>
                  <td class="col-12 sm:col-6 md:col-3">{{ user.rol }}</td>
                  <td class="col-12 sm:col-6 md:col-1 text-right">
                    <button type="button"
                              (click)="selectMenuItems(); menu.toggle($event); selectUser(user.id)"
                              pButton
                              icon="pi pi-ellipsis-v"
                              class="p-button-rounded p-button-text">
                      </button>
                      <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                  </td>
              </tr>
          </ng-template>
      </p-table>
      <p-paginator class="col-12" *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first" [rows]="defaultRows"
          [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
      </p-paginator>
    </div>
</section>
