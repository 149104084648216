import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import typeOfRemuneration from '../../../../../../core/jsons/typeOfRemuneration.json';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { GetFileUseCase } from 'src/app/features/claims/domain/usecases/get-file.usecase';
import { GetFileModel } from 'src/app/features/claims/data/models/get-file.model';
import { Failure } from 'src/app/core/utils/failure';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CalendarTranslationService } from 'src/app/core/services/translate-calendar.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import {Toast} from "primeng/toast";
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';


interface TypeRemeneration {
  id: number;
  type: string;
}

interface Localities {
  id: number;
  name: string;
}

interface Base64 {
  name: string;
  base64: string;
}

@Component({
  selector: 'app-claimant-details-form',
  templateUrl: './claimant-details-form.component.html',
  styleUrls: ['./claimant-details-form.component.css'],
  providers: [ConfirmationService, MessageService,MessageService,Toast],
})
export class ClaimantDetailsFormComponent implements OnInit, OnChanges {

  @Output() claimantDetailsForm: EventEmitter<FormGroup> = new EventEmitter();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();

  @Input() claim?: ClaimEntity;
  @Input() claimId?: string | null;
  @Input() userData?: UserData;


  typeRemunerations: TypeRemeneration[] = [...typeOfRemuneration];
  uploadedFiles: any[] = [];
  visibleCancel: boolean = false;
  showConditionalReason: boolean = false;
  showConditionalCause: boolean = false;
  disableFileImageUpload: boolean = false;
  disableFilePdfUpload: boolean = false;
  maxDate: Date | undefined;
  maxDateIngreso: Date | undefined;
  minDate: Date | undefined;

  maxDateForDateAdmission: Date | null = null;
  minDateForEgressDate: Date | null = null;
  updatingDateAdmission = false;
  updatingEgressDate = false;

  isDragging: boolean = false;
  imageFiles: any[] = [];
  pdfFiles: any[] = [];
  departments!: Departament[];
  progress: number = 0;

  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];
  selectedProvince?: ProvinceEntity;
  selectedDepartment?: Departament;
  public isEditMode: boolean = false;

  typeOfJob: any[] = [
    { name: 'Registrado', key: 'register' },
    { name: 'Parcialmente registrado', key: 'partRegister' },
  ];

  currentEmploymentsStatus: any[] = [
    { id: 1, name: 'Vigente' },
    { id: 2, name: 'Disuelta' },
  ];
  reasonsForDisengagement: any[] = [
    { id: 1, name: 'Renuncia' },
    { id: 2, name: 'Despido indirecto' },
    { id: 3, name: 'Mutuo acuerdo' },
    { id: 4, name: 'Despido' },
  ];
  causesDisengagement: any[] = [
    { id: 1, name: 'Invocación de causa' },
    { id: 2, name: 'Sin causa' },
    { id: 3, name: 'Articulo 247' },
  ];

  constructor(private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase,
    private getFileUseCase: GetFileUseCase,
    private calendarTranslationService: CalendarTranslationService,
    private messageService: MessageService,
    private getDepartmentsUsecase: GetDepartmentsUseCase
  ) {
    this.maxDate = new Date();
    this.maxDateIngreso = new Date();
    this.maxDateIngreso.setMonth(this.maxDateIngreso.getMonth() - 1);

    this.formClaimetDetails.get('claimetDateAdmission')?.valueChanges.subscribe(date => {
      if (!this.updatingDateAdmission) {
        this.updatingEgressDate = true;
        this.minDateForEgressDate = date;
        this.formClaimetDetails.get('claimetEgressDate')?.updateValueAndValidity();
        this.updatingEgressDate = false;
      }
    });

    this.formClaimetDetails.get('claimetEgressDate')?.valueChanges.subscribe(date => {
      if (!this.updatingEgressDate) {
        this.updatingDateAdmission = true;
        this.maxDateForDateAdmission = date;
        this.formClaimetDetails.get('claimetDateAdmission')?.updateValueAndValidity();
        this.updatingDateAdmission = false;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.initializeComponent();
    this.formClaimetDetails.get('claimetProvince')?.valueChanges.subscribe((selectedProvince: any) => {
      const province = this.getProvinceIdByName(selectedProvince);
      if (province) {
        this.loadLocalities(province);
        this.formClaimetDetails.patchValue({ provincia: province }, { emitEvent: false });
      }
    });
  }

  async initializeComponent(): Promise<void> {
    await this.loadProvinces();

    this.formClaimetDetails.controls['claimetCurrentEmploymentStatus'].valueChanges.subscribe(
      (selectedValue) => {
        this.showConditionalReason = selectedValue === 'Disuelta';
        this.showConditionalCause = false;
      }
    );

    this.formClaimetDetails.controls['claimetReasonForDisengagement'].valueChanges.subscribe(
      (selectedValue) => {
        this.showConditionalCause = selectedValue === 'Despido';
      }
    );
    this.loadDepartments();

    this.calendarTranslationService.configureCalendarTranslations();
    this.formClaimetDetails.patchValue({department_number: this.selectedDepartment?.id});
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['userData'] && changes['userData'].currentValue) {
      this.loadUserData();
    }

    if (changes['claim'] && changes['claim'].currentValue) {
      this.isEditMode = true;
      await this.initializeFormWithClaimData();
    }

  }
  

  formClaimetDetails: FormGroup = this.formBuilder.group({
    claimetLastname: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/)]],
    claimetName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/)]],
    claimetCuil: ['', [Validators.required]],
    claimetEmail: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
    claimetPhone: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(/^[0-9]+$/)]],
    claimetProvince: ['', Validators.required],
    claimetCity: ['', [Validators.required]],
    claimetNeighborhood: ['', [Validators.required]],
    claimetStreet: ['', [Validators.required, Validators.maxLength(50)]],
    claimetNumber: ['', [Validators.required, Validators.maxLength(5)]],
    claimetPostalCode: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(4), Validators.pattern(/^[a-zA-Z0-9]*$/)]],
    claimetFloorApartament: ['', [Validators.maxLength(10)]],
    claimetTower: ['', [Validators.maxLength(30)]],
    claimetSelectedTypeOfJob: ['', [Validators.required]],
    claimetWorkday: ['', [Validators.required,Validators.maxLength(80),Validators.pattern(/^[a-zA-Z0-9\s]*$/)]],
    claimetGrossRemuneration: ['', [Validators.required, Validators.maxLength(10)]],
    claimetTypeRemuneration: ['', [Validators.required]],
    claimetDateAdmission: ['', [Validators.required]],
    claimetEgressDate: [],
    claimetNumberAgreement: ['aa2024'],
    claimetYear: ['0000'],
    claimetCategory: ['aa'],
    claimetCurrentEmploymentStatus: ['', [Validators.required]],
    claimetReasonForDisengagement: [''],
    claimetCauseDisengagement: [''],
    claimetFileDni: [, [this.CountValidatorDni]],
    claimetFilePaycheck: [, [Validators.required]],
    department_number:['',[Validators.required]],
  });

  private loadUserData() {
    if (this.userData) {
      this.formClaimetDetails.patchValue({
        claimetLastname: this.userData.lastName,
        claimetName: this.userData.name,
        claimetCuil: this.userData.cuil,
        claimetEmail: this.userData.email,
        claimetPhone: this.userData.phone,
        claimetProvince: this.userData.province,
        claimetCity: this.userData.city,
        claimetNeighborhood: this.userData.neighborhood || this.claim?.claimetNeighborhood,
        claimetStreet: this.userData.street,
        claimetNumber: this.userData.number.toString(),
        claimetPostalCode: this.userData.postalCode,
        claimetFloorApartament: this.userData.floorApartment,
        claimetTower: this.userData.tower,
      })
    }
  }

  async loadDepartments() {
    const departments = await this.getDepartmentsUsecase.execute();
    if (Array.isArray(departments)) {
      this.departments = this.putIdToDepartmentsList(departments);
    }
  }

  putIdToDepartmentsList(list: Departament[]): Departament[] {
    let listWithId: Departament[] = [];
    for (let i = 0; i < list.length; i++) {
      let department: Departament = {
        id: list[i].id,
        name: list[i].name
      };
      listWithId.push(department);
    }
    return listWithId;
  }

  private getProvinceIdByName(provinceName: string): string | undefined {
    const province = this.provinces.find(p => p.name === provinceName);

    return province ? province.id : undefined;
  }

  yearValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const value = control.value;
      if (value && (value.toString().length !== 4 || isNaN(value))) {
        return { 'invalidYear': true };
      }
      return null;
    };
  }

  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }

    if (input.value.length >= 4 && input.selectionStart === input.selectionEnd) {
      event.preventDefault();
    }

    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  private async initializeFormWithClaimData(): Promise<void> {
    if (!this.claim) return;
    if (this.isEditMode) {
      this.formClaimetDetails.patchValue({
        claimetLastname: this.claim.claimetLastname || '',
        claimetName: this.claim.claimetName || '',
        claimetCuil: this.claim.claimetCuil || '',
        claimetEmail: this.claim.claimetEmail || '',
        claimetPhone: this.claim.claimetPhone || '',
        claimetProvince: this.claim.claimetProvince || '',
        claimetCity: this.claim.claimetCity || '',
        claimetNeighborhood: this.claim.claimetNeighborhood || '',
        claimetStreet: this.claim.claimetStreet || '',
        claimetNumber: this.claim.claimetNumber || '',
        claimetPostalCode: this.claim.claimetPostalCode || '',
        claimetFloorApartament: this.claim.claimetFloorApartament || '',
        claimetTower: this.claim.claimetTower || '',
        claimetSelectedTypeOfJob: this.claim.claimetSelectedTypeOfJob || '',
        claimetWorkday: this.claim.claimetWorkday || '',
        claimetGrossRemuneration: this.claim.claimetGrossRemuneration || '',
        claimetTypeRemuneration: this.claim.claimetTypeRemuneration || '',
        claimetDateAdmission: this.claim.claimetDateAdmission || '',
        claimetEgressDate: this.claim.claimetEgressDate || '',
        claimetNumberAgreement: this.claim.claimetNumberAgreement || '',
        claimetYear: this.claim.claimetYear || '',
        claimetCategory: this.claim.claimetCategory || '',
        claimetCurrentEmploymentStatus: this.claim.claimetCurrentEmploymentStatus || '',
        claimetReasonForDisengagement: this.claim.claimetReasonForDisengagement || '',
        claimetCauseDisengagement: this.claim.claimetCauseDisengagement || '',
        claimetFileDni: this.claim.claimetFileDni || '',
        claimetFilePaycheck: this.claim.claimetFilePaycheck || '',
        department_number: this.claim.department_number
      });
      this.formClaimetDetails.get('claimetDateAdmission')?.setValue(this.parseDate(this.formClaimetDetails.get('claimetDateAdmission')?.value));
      this.formClaimetDetails.get('claimetEgressDate')?.setValue(this.parseDate(this.formClaimetDetails.get('claimetEgressDate')?.value));
      await this.initializeFormWithSavedFiles();
    }
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any, fileType: string):any {
    const file = event.dataTransfer?.files;
    if(!file[0].type.startsWith('image/') && fileType == "image"){
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Solo se permiten imagenes.",
      });
      return false;
    }else if (!file[0].type.startsWith('application/pdf') && fileType == "pdf") {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Solo se permiten PDF.",
      });
      return false;
    }else {
      event.preventDefault();
      this.isDragging = false;
      this.onFileChange(file, fileType);
    }
  }

  onChange(event: any, fileType: string):any {

    const input = event.target as HTMLInputElement;
    const files = event.target.files;

    if(files.length > 2 ){
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Solo se permiten 2 archivos.",
      });
      return false;
    }

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if(!files[i].type.startsWith('image/') && fileType == "image"){
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Solo se permiten imagenes.",
          });
          return false;
        }else if (!files[i].type.startsWith('application/pdf') && fileType == "pdf") {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Solo se permiten PDF.",
          });
          return false;
        }

        this.convertToBase64(files[i], fileType);
      }
      input.value = '';
    }
  }

  private CountValidatorDni(control: AbstractControl): { [key: string]: any } | null {
    return control.value?.length === 2 ? null : { fileCount: true };
  }
  

  private onFileChange(files: any, fileType: string) {
    this.progress = 0;
    if (fileType === 'image') {
      const newFiles = Array.from(files);
      this.imageFiles = (this.imageFiles || []).concat(newFiles).slice(-2);
    } else if (fileType === 'pdf') {
      const newFiles = Array.from(files);
      this.pdfFiles = (this.pdfFiles || []).concat(newFiles).slice(-2);
    }
  }

  private assignImages(file: any, base64String: string) {
    this.imageFiles.push({ name: file.name, base64: base64String });
    const fileDniBase64Array = this.imageFiles.map((image) => image.base64);
    this.formClaimetDetails.get("claimetFileDni")?.setValue(fileDniBase64Array);
    this.formClaimetDetails.get("claimetFileDni")?.markAsTouched();
    this.formClaimetDetails.get("claimetFileDni")?.updateValueAndValidity();
    this.checkFileImageCount();
  }

  private assignPdf(file: any, base64String: string) {
    this.pdfFiles.push({ name: file.name, base64: base64String });
    const filePdfBase64Array = this.pdfFiles.map((pdf) => pdf.base64);
    this.formClaimetDetails.get("claimetFilePaycheck")?.setValue(filePdfBase64Array)
    this.checkFilePdfCount();
  }

  private async convertToBase64(file: any, fileType: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        if (fileType === 'image') {
          this.assignImages(file, base64String)

        } else {
          this.assignPdf(file, base64String)
        }
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
    };
    reader.readAsDataURL(file);
  }

  deleteFileImage(index: number) {
    this.imageFiles.splice(index, 1);
    const fileDniBase64Array = this.imageFiles.map((image) => image.base64);
    this.formClaimetDetails.get("claimetFileDni")?.setValue(fileDniBase64Array.length ? fileDniBase64Array : null);
    this.formClaimetDetails.get("claimetFileDni")?.markAsTouched();
    this.formClaimetDetails.get("claimetFileDni")?.markAsDirty();
    this.checkFileImageCount();
  }

  deleteFilePfd() {
    this.pdfFiles = [];
    const fileDniControl = this.formClaimetDetails.get("claimetFilePaycheck");
    fileDniControl?.setValue(null);
    fileDniControl?.markAsTouched();
    fileDniControl?.markAsDirty();
    this.checkFilePdfCount();
  }

  private checkFileImageCount() {
    this.disableFileImageUpload = this.imageFiles.length >= 2;
  }

  private checkFilePdfCount() {
    this.disableFilePdfUpload = this.pdfFiles.length >= 1;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formClaimetDetails.get(controlName)?.hasError(errorType) &&
      this.formClaimetDetails.get(controlName)?.touched
    );
  }

  nextStep() {
    if (this.formClaimetDetails.invalid) return;
    this.claimantDetailsForm.emit(this.formClaimetDetails.value);
    this.stepIndex.emit(1);
  }

  cancel() {
    this.activeModal.emit(true);
  }

  onYearSelect(event: any): void {
    const selectedDate = new Date(event);
    const selectedYear = selectedDate.getFullYear().toString();
    this.formClaimetDetails.get('claimetYear')?.setValue(selectedYear);
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;

      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        this.localities = this.putIdToLocalitiesList(localities);
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  minDateValidator(minDate: Date) {
    return (control: FormControl) => {
      const controlDate = new Date(control.value);
      if (control.value && controlDate < minDate) {
        return { minDate: true };
      }
      return null;
    };
  }

  private async initializeFormWithSavedFiles(): Promise<void> {
    for (let i = 0; i < this.formClaimetDetails.get("claimetFileDni")?.value.length; i++) {
      let dniFiles = new GetFileModel(
        this.claim?.id!,
        this.claim?.claimetFileDni[i]!,
      )
      let bas64 = await this.getFile(dniFiles);
      bas64 = 'data:image/png;base64,' + bas64;
      let base64Objet: Base64 = {
        name: 'DNI' + (i+1),
        base64: bas64
      }
      this.imageFiles.push(base64Objet);
    }
    let paycheckFile = new GetFileModel(
      this.claim?.id!,
      this.claim?.claimetFilePaycheck[0]!,
    )
    let bas64 = await this.getFile(paycheckFile);
    bas64 = 'data:application/pdf;base64,' + bas64;
    this.pdfFiles.push(bas64);

    this.formClaimetDetails.get("claimetFileDni")?.setValue(this.imageFiles);
    this.formClaimetDetails.get("claimetFilePaycheck")?.setValue(this.pdfFiles);
  }

  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  parseDate(date: string): Date {
    if(!date) {
      return new Date(1930, 0, 1);
    }
    let part = date.split(/\/| |:/);
    return new Date(
      parseInt(part[2]),
      parseInt(part[0]) - 1,
      parseInt(part[1]),
      parseInt(part[3]),
      parseInt(part[4]),
      parseInt(part[5])
    );
  }

  showError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Message Content' });
  }

}
