import { Failure } from "src/app/core/utils/failure";
import { MinuteEntity } from "../../domain/entities/minute.entity";
import { environment } from "src/environments/environment.dev";
import { HttpServiceBase } from "src/app/core/services/http.service";
import { LocalStorageServiceBase } from "src/app/core/services/localStorage-serviceBase";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MinutePdf } from "../../domain/entities/minutePdf.entity";

export abstract class MinuteRemoteDataSourceBase {
    abstract createMinute(audience: MinutePdf): Promise<string | Failure>;
    abstract getMinutesByClaimId(id: string): Promise<MinuteEntity[] | Failure>;

}

@Injectable()
export class MinuteRemoteDataSource extends MinuteRemoteDataSourceBase {

    private url: string = `${environment.apiUrl}/Acta`;

    constructor(private httpService: HttpServiceBase,
        private localStorageService: LocalStorageServiceBase) {
        super();
    }

    override async createMinute(audience: MinutePdf): Promise<string | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.post(`${this.url}`, audience, headers);
        return result.get('response');
    }

    override async getMinutesByClaimId(id: string): Promise<Failure | MinuteEntity[]> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/get_all_minute_by_claim_id?id=${id}`, headers);
        return result.get('response');    }

}
