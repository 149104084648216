<form [formGroup]="minuteForm" (ngSubmit)="createMinute()">
    <div  *ngIf="!isLoading" class="grid">

      <div class="col-12">
        <p class="text-xs font-medium m-0">Cargue el documento a continuación.</p>
      </div>

      <div class="col-12 mb-3">
        <div [ngClass]="isDragging ? 'isDragging dropzone-container h-4rem col-12' : 'dropzone-container h-4rem col-12'"
          (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
          <input #inputFile type="file" multiple (change)="onChange($event, 'pdf')" accept=".pdf" maxFileSize="1000000"
            [disabled]="disableFileImageUpload" />

          <div class="flex align-items-center justify-content-between col-12">
            <img class="hidden md:block" src="../../../../../../../assets/img/main/file-update.svg" />
            <span class="descriptionContent hidden md:block">Arrastre sus documentos aquí o</span>
            <p-button pRipple (click)="inputFile.click()" #upload class="btnUploadFile mr-1 w-auto"
              [disabled]="disableFileImageUpload">Elegir</p-button>
          </div>
        </div>
        <span class="descriptionContent">Archivos permitidos: .pdf</span>
      </div>

      <div class="col-12 md:col-12 conteiner-UploadRight">
        <div class="imageContainer" *ngFor="let image of minuteFile; let i = index">
          <img src="../../../../../../../assets/img/main/icon-pdf.svg" alt="" class="icon-imagen" />
          <span class="descriptionContent">{{ image.name }}</span>
          <p-button class="closeButton" icon="pi pi-times" [rounded]="true" [text]="true" severity="danger"
            (onClick)="deleteFileImage(i)"></p-button>
        </div>
      </div>


      <div class="grid w-full flex justify-content-end ml-1 mt-3">
        <div class="col-6 md:col-6">
          <button type="button" pButton label="Cancelar" class="btn-cancelar" (click)="cancel()"
                  style="background-color: rgb(238, 233, 233); color: #263245; border: 1px ridge #263245;"></button>
        </div>
        <div class="col-6 md:col-6">
          <button type="submit" pButton class="btn-siguiente"
                  style="
              background-color: #263245;
              border-color: #263245;
              color: white !important;
              font-size: 0.8rem;
              font-weight: bold;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin: 0 auto;"
                  [disabled]="minuteForm.invalid">Subir acta</button>
        </div>
      </div>

    </div>
  <div class="justify-content-center align-content-center md:ml-3">
  <app-spinner *ngIf="isLoading"></app-spinner>
  </div>

</form>
