<p-toast />
<form
  [formGroup]="formClaimetDetails"
  (ngSubmit)="nextStep()"
  class="w-full relative mt-6"
>
  <section class="personalInformation">
    <span class="titleSection">Datos personales</span>

    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle">Apellido/s <span class="span">*</span></label>
        <input
          class="disabled"
          [readonly]="true"
          pInputText
          formControlName="claimetLastname"
          maxlength="50"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle">Nombre/s<span class="span">*</span></label>
        <input
          class="disabled"
          [readonly]="true"
          type="text"
          pInputText
          formControlName="claimetName"
          maxlength="50"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
        <label class="labelTitle">CUIL<span class="span">*</span></label>
        <input
          class="disabled"
          [readonly]="true"
          type="text"
          formControlName="claimetCuil"
          pInputText
        />
      </div>
    </div>

    <div class="grid w-full mb-2">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Correo electronico<span class="span">*</span></label
        >
        <input
          class="disabled"
          [readonly]="true"
          type="text"
          pInputText
          formControlName="claimetEmail"
          type="email"
          maxlength="100"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle">Teléfono<span class="span">*</span></label>
        <input
          pInputText
          formControlName="claimetPhone"
          maxlength="15"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetPhone', 'required') ||
              hasErrors('claimetPhone', 'minlength') ||
              hasErrors('claimetPhone', 'maxlength') ||
              hasErrors('claimetPhone', 'pattern')
          }"
        />

        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'minlength')"
        >
          Este campo no puede poseer menos de 7 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'maxlength')"
        >
          Este campo no puede poseer más de 15 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'pattern')"
        >
          El campo solo acepta números. Las letras o caracteres especiales no
          son permitidos.
        </div>
      </div>
    </div>

    <div class="grid w-full mb-2">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle">Calle<span class="span">*</span></label>
        <input
          type="text"
          maxlength="50"
          pInputText
          formControlName="claimetStreet"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetStreet', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetStreet', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-2 xl:col-2 sm:col-6">
        <label class="labelTitle"
          >Número calle<span class="span">*</span></label
        >
        <input
          type="text"
          pInputText
          formControlName="claimetNumber"
          maxlength="5"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetNumber', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetNumber', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-2 xl:col-2 sm:col-6">
        <label class="labelTitle">Piso/Depto</label>
        <input
          type="text"
          pInputText
          formControlName="claimetFloorApartament"
          maxlength="10"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-2 xl:col-2 sm:col-6">
        <label class="labelTitle">Torre</label>
        <input
          type="text"
          pInputText
          formControlName="claimetTower"
          maxlength="30"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-2 xl:col-2">
        <label class="labelTitle"
          >Código postal<span class="span">*</span></label
        >
        <input
          type="text"
          pInputText
          formControlName="claimetPostalCode"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetPostalCode', 'required') ||
              hasErrors('claimetPostalCode', 'maxlength') ||
              hasErrors('claimetPostalCode', 'minlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPostalCode', 'minlength')"
        >
          El campo solo acepta un máximo de 10 dígitos.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPostalCode', 'minlength')"
        >
          El campo solo acepta un mínimo de 4 dígitos.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPostalCode', 'required')"
        >
          Este campo es obligatorio.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPostalCode', 'pattern')"
        >
          El campo solo acepta números y letras. Los caracteres especiales no
          son permitidos.
        </div>
      </div>
    </div>

    <div class="grid w-full mb-2">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle">Barrio<span class="span">*</span></label>
        <input
          type="text"
          pInputText
          maxlength="50"
          formControlName="claimetNeighborhood"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetNeighborhood', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetNeighborhood', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle" for="claimetProvince" optionLabel="name"
          >Provincia<span class="span">*</span></label
        >
        <p-dropdown
          id="claimetProvince"
          [options]="provinces"
          optionLabel="name"
          optionValue="name"
          placeholder="Seleccione su Provincia"
          formControlName="claimetProvince"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetProvince', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetProvince', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle">Localidad<span class="span">*</span></label>
        <p-dropdown
          [options]="localities"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="claimetCity"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetCity', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetCity', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>
  </section>
  <p-divider></p-divider>
  <section class="employmentRelationshipDetails mt-3">
    <span class="titleSection">Datos de la relación laboral</span>

    <div class="grid w-full mt-3">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
        <label class="text-sm font-bold mr-3"
          >Tipo de trabajo<span class="span">*</span></label
        >
      </div>
      <div
        class="col-12 md:col-6 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1"
      >
        <div *ngFor="let job of typeOfJob" class="flex align-items-center">
          <p-radioButton
            [inputId]="job.key"
            [value]="job.name"
            formControlName="claimetSelectedTypeOfJob"
          ></p-radioButton>
          <label [for]="job.key" class="text-sm ml-2 gap-1 mr-3">{{
            job.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetSelectedTypeOfJob', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>

    <div class="grid w-full mt-3">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
        <label class="text-sm font-bold mr-3"
          >Estado actual de la relación laboral<span class="span"
            >*</span
          ></label
        >
      </div>

      <div
        class="col-12 md:col-4 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1"
      >
        <div
          *ngFor="let a of currentEmploymentsStatus"
          class="flex align-items-center"
        >
          <p-radioButton
            [inputId]="a.id"
            [value]="a.name"
            formControlName="claimetCurrentEmploymentStatus"
          ></p-radioButton>
          <label [for]="a.id" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetCurrentEmploymentStatus', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div
        class="col-12 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1 mt-2"
        *ngIf="showConditionalReason"
      >
        <div
          *ngFor="let a of reasonsForDisengagement"
          class="flex align-items-center"
        >
          <p-radioButton
            [inputId]="a.id"
            [value]="a.name"
            formControlName="claimetReasonForDisengagement"
          ></p-radioButton>
          <label [for]="a.id" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetReasonForDisengagement', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div
        class="col-12 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1 mt-2"
        *ngIf="this.showConditionalCause"
      >
        <div
          *ngFor="let a of causesDisengagement"
          class="flex align-items-center"
        >
          <p-radioButton
            [inputId]="a.id"
            [value]="a.name"
            formControlName="claimetCauseDisengagement"
          ></p-radioButton>
          <label [for]="a.id" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetCauseDisengagement', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>

    <div class="grid w-full mt-6">
      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Jornada laboral<span class="span">*</span></label
        >
        <input
          type="text"
          maxlength="80"
          pInputText
          formControlName="claimetWorkday"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetWorkday', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetWorkday', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetWorkday', 'pattern')"
        >
          El campo solo acepta números y letras. Los caracteres especiales no
          son permitidos.
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Departamento<span class="span">*</span></label
        >
        <p-dropdown
          type="text"
          [options]="departments"
          [(ngModel)]="selectedDepartment"
          optionLabel="name"
          optionValue="id"
          formControlName="department_number"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('department_number', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('department_number', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Remuneración bruta<span class="span">*</span></label
        >
        <p-inputNumber
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
          class="w-full"
          formControlName="claimetGrossRemuneration"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetGrossRemuneration', 'required') ||
              hasErrors('claimetGrossRemuneration', 'maxlength')
          }"
        >
        </p-inputNumber>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetGrossRemuneration', 'minlength')"
        >
          El campo solo acepta un máximo de 10 dígitos.
        </div>

        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetGrossRemuneration', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Tipo de remuneración<span class="span">*</span></label
        >
        <p-dropdown
          [options]="typeRemunerations"
          optionLabel="type"
          optionValue="type"
          [showClear]="false"
          placeholder="Elija una opción"
          formControlName="claimetTypeRemuneration"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors(
              'claimetTypeRemuneration',
              'required'
            )
          }"
        ></p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetTypeRemuneration', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Fecha de ingreso<span class="span">*</span></label
        >
        <p-calendar
          formControlName="claimetDateAdmission"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [maxDate]="maxDateIngreso"
          [minDate]="minDate"
          inputId="icondisplay"
          placeholder="01/01/1900"
          slotChar="dd/mm/yyyy"
          dateFormat="dd/mm/yy"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetDateAdmission', 'required')
          }"
        ></p-calendar>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetDateAdmission', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle">Fecha de egreso</label>
        <p-calendar
          formControlName="claimetEgressDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [maxDate]="maxDate"
          [minDate]="minDateForEgressDate || minDate"
          inputId="icondisplay"
          placeholder="01/01/1900"
          slotChar="dd/mm/yyyy"
          dateFormat="dd/mm/yy"
          [disabled]="
            formClaimetDetails.controls['claimetCurrentEmploymentStatus']
              .value === 'Vigente'
          "
        ></p-calendar>
      </div>
    </div>

    <div class="grid w-full mt-6">
      <div class="col">
        <label class="labelTitle mt-3 text-sm font-bold"
          >Convenio colectivo:</label
        >
      </div>
    </div>
    <div class="grid w-full">
      <p class="font-bold pl-2">CCT - Camioneros Santa Fé</p>
    </div>
  </section>
  <p-divider></p-divider>

  <section class="attachedFiles mt-3">
    <div class="grid w-full mt-1">
      <div class="col">
        <span class="titleSection">Archivos adjuntos</span>
      </div>
    </div>

    <div class="grid w-full">
      <div class="col-12">
        <label class="labelTitle"
          >DNI frente y dorso<span class="span">*</span></label
        >
        <p class="descriptionContent mb-0">
          Cargue el frente y dorso de su Documento Nacional de Identidad
        </p>
      </div>

      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <div
          [ngClass]="
            isDragging
              ? 'isDragging dropzone-container h-6rem col-12 '
              : 'dropzone-container h-6rem col-12  '
          "
          (dragover)="onDragOver($event)"
          (drop)="onDropSuccess($event, 'image')"
        >
          <input
            #inputFile
            type="file"
            multiple="true"
            (change)="onChange($event, 'image')"
            accept="image/*"
            maxFileSize="1000000"
            [disabled]="disableFileImageUpload"
          />

          <div
            class="w-full flex align-items-center justify-content-between col-12"
          >
            <img
              class="hidden md:block"
              src="../../../../../../../assets/img/main/file-update.svg"
            />
            <span class="descriptionContent hidden md:block"
              >Arrastre sus documentos aquí o</span
            >
            <p-button
              pRipple
              (click)="inputFile.click()"
              #upload
              class="btnUploadFile mr-1"
              [disabled]="disableFileImageUpload"
              >Elegir archivo</p-button
            >
          </div>
        </div>
        <span class="descriptionContent">Archivos permitidos: Imagen</span>
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-6 conteiner-UploadRight">
        <div
          class="text-danger p-error"
          *ngIf="
            formClaimetDetails.get('claimetFileDni')?.hasError('fileCount') &&
            formClaimetDetails.get('claimetFileDni')?.touched
          "
        >
          Se debe subir exactamente 2 archivos.
        </div>
        <div
          class="imageContainer"
          *ngFor="let image of imageFiles; let i = index"
        >
          <img
            src="../../../../../../../assets/img/main/icon-image.svg"
            alt=""
            class="icon-imagen"
          />
          <span class="descriptionContent">{{ image.name }}</span>
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger"
            (onClick)="deleteFileImage(i)"
          ></p-button>
        </div>
      </div>
    </div>

    <div class="grid w-full mt-3">
      <div class="col-12">
        <label class="labelTitle"
          >Recibo de sueldo<span class="span">*</span></label
        >
        <p class="descriptionContent mb-0">Cargue su recibo de sueldo</p>
      </div>

      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <div
          [ngClass]="
            isDragging
              ? 'isDragging dropzone-container h-6rem col-12 '
              : 'dropzone-container h-6rem col-12 '
          "
          (dragover)="onDragOver($event)"
          (drop)="onDropSuccess($event, 'pdf')"
        >
          <input
            #inputFilePfd
            type="file"
            multiple="false"
            (change)="onChange($event, 'pdf')"
            accept=".pdf"
            [disabled]="disableFilePdfUpload"
          />

          <div
            class="w-full flex align-items-center justify-content-between col-12"
          >
            <img
              class="hidden md:block"
              src="../../../../../../../assets/img/main/file-update.svg"
            />
            <span class="descriptionContent hidden md:block"
              >Arrastre sus documentos aquí o</span
            >
            <p-button
              pRipple
              (click)="inputFilePfd.click()"
              #upload
              class="btnUploadFile mr-1"
              [disabled]="disableFilePdfUpload"
              >Elegir archivo</p-button
            >
          </div>
        </div>
        <span class="descriptionContent">Archivos permitidos: PDF</span>
      </div>

      <div class="col-12 md:col-6 lg:col-6 xl:col-6">
        <div class="conteiner-UploadRight-pdf" *ngIf="pdfFiles.length">
          <img
            src="../../../../../../../assets/img/main/icon-image.svg"
            alt=""
            class="icon-imagen"
          />
          <span *ngIf="pdfFiles[0].name" class="descriptionContent">{{
            pdfFiles[0].name
          }}</span>
          <span *ngIf="!pdfFiles[0].name" class="descriptionContent"
            >Recibo de sueldo</span
          >
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger"
            (onClick)="deleteFilePfd()"
          ></p-button>
        </div>
      </div>
    </div>
  </section>
  <div class="grid w-full flex justify-content-end">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        [disabled]="formClaimetDetails.invalid"
        type="submit"
      ></p-button>
    </div>
  </div>
</form>
