import { UseCase } from "src/app/core/utils/usecase";
import { Departament } from "src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component";
import { ProvinceRepositoryBase } from "../repositories/province-base.repository";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";

@Injectable()
export class GetDepartmentsUseCase implements UseCase<string, Departament[] | Failure> {

    constructor(private provinceRepository: ProvinceRepositoryBase){
        
    }

   async execute(): Promise<Departament[] | Failure> {
        return await this.provinceRepository.getDepartments();
   }
}