<div class="flex flex-column w-full h-full gap-3 p-4">
  <div class="block align-items-center justify-content-between px-2 sm:flex">
    <p class="font-semibold text-xl title">{{ titleText }}</p>
    <div *ngIf="rol === 'Trabajador'">
      <p-button
        class="btn-new-claim"
        label="Nuevo reclamo laboral"
        icon="pi pi-plus"
        routerLink="/main/newLaboralClaim"
      ></p-button>
    </div>
  </div>

  <ng-container>
    <app-claims-table *ngIf="!isCommisionRol"></app-claims-table>
    <app-claims-comision-table
      *ngIf="isCommisionRol"
    ></app-claims-comision-table>
  </ng-container>
</div>
