<section class="m-1 pb-2 sm:mx-6 sm:my-3 ">
  <div class="mb-3">
    <ng-container>
      <div class="flex flex-column">
        <div class="notification-sign p-2">
          <div class="flex flex-column">
            <span class="col-12 text-xl pb-0 font-semibold"
              >Bienvenidos a SICOL - Un Sistema de la Comisión Paritaria</span
            >
            <span class="col-12 text-xl pt-1 font-semibold"
              >Permanente de Relaciones Laborales</span
            >
          </div>
          <div class="grid p-2">
            <div class="col-6">
              <div
                *ngIf="!isConciliatorRol"
                class="col-12 flex flex-row align-items-center p-1 gap-2"
              >
                <i class="pi pi-clock gap-2"></i>
                <span *ngIf="!nextSetAudience" class=""
                  >No tiene audiencias fijadas</span
                >
                <span *ngIf="nextSetAudience" class=""
                  >Próxima audiencia
                  <span class="changeColor">{{
                    nextAudienceDate | date : "d 'de' MMMM" : "es" | titlecase
                  }}</span>
                  a las
                  <span class="changeColor"
                    >{{ nextSetAudience.time }}hs.</span
                  ></span
                >
              </div>
              <div
                *ngIf="isConciliatorRol"
                class="col-12 flex flex-row align-items-center p-1 gap-2"
              >
                <i class="pi pi-clock gap-2"></i>
                <span *ngIf="!nextAudienceOfConciliator" class=""
                  >No tiene audiencias fijadas</span
                >
                <span *ngIf="nextAudienceOfConciliator" class=""
                  >Próxima audiencia
                  <span class="changeColor">{{
                    nextAudienceDate | date : "d 'de' MMMM" : "es" | titlecase
                  }}</span>
                  a las
                  <span class="changeColor"
                    >{{ nextAudienceOfConciliator.time }}hs.</span
                  ></span
                >
              </div>
              <div class="col-12 flex flex-row align-items-center p-1 gap-2">
                <i class="pi pi-file gap-2"></i>
                <span *ngIf="newClaims == 0"
                  >No tiene reclamos laborales nuevos</span
                >
                <span *ngIf="newClaims != 0">
                  Tiene
                  <span class="changeColor">{{ newClaims }} reclamos</span>
                  nuevos</span
                >
              </div>
            </div>
            <div class="col-12 md:col-6 btnComisionHome">
              <p-button
                label="Ver audiencias"
                class=""
                routerLink="/main/audiences"
              ></p-button>
              <p-button
                label="Ver reclamos"
                class=""
                routerLink="/main/claims"
              ></p-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="shadow-3 surface-card p-2 mb-3 border-round-lg">
      <p class="font-semibold text-xl title pl-3">Próximas audiencias fijadas</p>
      <ng-container>
        <app-audience-table-next></app-audience-table-next>
      </ng-container>
  </div>

  <div class="shadow-3 surface-card p-2 border-round-lg">
    <p class="font-semibold text-xl title pl-3">Últimos reclamos laborales</p>
    <ng-container>
      <app-claims-comision-table></app-claims-comision-table>
    </ng-container>
  </div>
</section>
