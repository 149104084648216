import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";
import { GetFileBaseEntity } from "../entities/get-file-base.entity";

@Injectable()
export class GetFileBaseUseCase implements UseCase<GetFileBaseEntity, string | Failure> {

    constructor(private claimRepository: ClaimRepositoryBase) {}

    async execute(param: GetFileBaseEntity): Promise<string | Failure> {
        return await this.claimRepository.getFileBase(param);
    }
}
