export abstract class Failure {
  constructor(public message: string, public code: number) {}
}

export class UnhandledFailure extends Failure {
  constructor(
    message: string = 'Error interno del servidor. Intente más tarde.',
    code: number = 500
  ) {
    super(message, code);
  }
}

export class NotFoundFailure extends Failure {
  constructor() {
    super('No se pudo encontrar el recurso buscado.', 404);
  }
}

export class InvalidRepeatedPassword extends Failure {
  constructor() {
    super('La nueva contraseña no puede ser igual a la contraseña actual', 400);
  }
}

export class BadRequestFailure extends Failure {
  constructor(
    message: string = 'Está realizando una operacion de manera incorrecta.'
  ) {
    super(message, 400);
  }
}

export class InvalidAuthentication extends Failure {
  constructor() {
    super('Usuario/contraseña incorrectas.', 401);
  }
}

export class InvalidGrant extends Failure {
  constructor() {
    super(
      'La cuenta no está completamente configurada. Debe validar su cuenta vía correo electrónico.',
      400
    );
  }
}

export class ExistingResourceFailure extends Failure {
  constructor(message: string, code: number) {
    super(message, code);
  }
}

export class ForbiddenFailure extends Failure {
  constructor(message: string = 'No se encuentra autenticado en el sistema.') {
    super(message, 403);
  }
}

export class InvalidActualPasswordFailure extends Failure {
  constructor(message: string = 'Contraseña actual incorrecta.') {
    super(message, 403);
  }
}

export class NotFoundAccount extends Failure {
  constructor(
    message: string = 'No se encontró ninguna cuenta con ese correo electrónico'
  ) {
    super(message, 404);
  }
}
