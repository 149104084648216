import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function OfLegalAgeValidator(): ValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return new Promise((resolve) => {
      const today = new Date();
      const birthDate = new Date(control.value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      const dayDifference = today.getDate() - birthDate.getDate();
      if (
        age > 18 ||
        (age === 18 && monthDifference > 0) ||
        (age === 18 && monthDifference === 0 && dayDifference >= 0)
      ) {
        resolve(null);
      } else {
        resolve({ underage: true });
      }
    });
  };
}
