import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';

type ValidatorKeys = 'uppercase' | 'lowercase' | 'minlength' | 'number' | 'specialChar';

interface City {
  id: string;
  name: string;
}

@Component({
  selector: 'app-update-user-config',
  templateUrl: './update-user-config.component.html',
  styleUrls: ['./update-user-config.component.css']
})
export class UpdateUserConfigComponent implements OnInit{
  personalDataForm: FormGroup;
  sexOptions = ['Masculino', 'Femenino', 'Prefiero no decirlo'];
  civilStatusOptions = ['Soltero', 'Casado', 'Divorciado', 'Viudo'];

  provinces: ProvinceEntity[] = [];
  localities: LocationEntity[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase,
    private location: Location
  ) {
    this.personalDataForm = this.formBuilder.group({
      apellido: ['', Validators.required],
      nombre: ['', Validators.required],
      cuil: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]],
      fechaNacimiento: ['', Validators.required],
      sexo: ['', Validators.required],
      estadoCivil: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      numero: ['', Validators.required],
      pisoDepto: [''],
      calle: ['', Validators.required],
      provincia: ['', Validators.required],
      localidad: ['', Validators.required],
      codigoPostal: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]]
    });
  }

  async ngOnInit() {
    try {
      await this.loadProvinces();
    } catch (error) {
      console.error('Initialization error:', error);
    }
  }


  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();

      if (Array.isArray(result)) {
        this.provinces = result;
      }

    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        this.localities = localities;
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

cancel() {
  this.location.back();
}

hasErrors(controlName: string, errorType: string): boolean {
  const control = this.personalDataForm.get(controlName);
  return !!(control && control.hasError(errorType) && control.touched);
}

  onSubmit() {
    if (this.personalDataForm.valid) {
      console.log(this.personalDataForm.value);
    }
  }
}
