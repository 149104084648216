<section class="m-1 pb-2 sm:mx-6 sm:my-3">
  <div class="notification p-2 border-round-sm">
    <div class="flex flex-column">
      <span class="text-xl font-semibold"
        >Bienvenidos a SICOL - Un Sistema de la Comisión Paritaria</span
      >
      <span class="text-xl pt-1 font-semibold"
        >Permanente de Relaciones Laborales</span
      >
    </div>
    <div class="p-2">
      <div class="col-6 flex align-items-center gap-2">
        <button label="Añadir Usuario" class="btn px-3 py-2 border-round-sm sm:px-4 py-3" routerLink="/main/createUsers">
          Añadir Usuario
        </button>
        <button label="Añadir Empresa" class="btn px-3 py-2 border-round-sm sm:px-4 py-3" routerLink="/main/newCompany">
          Añadir Empresa
        </button>
      </div>
    </div>
  </div>

  <div class="shadow-2 p-2 mt-2 surface-card border-round-sm">
    <span class="text-xl pb-0 font-semibold">Últimos usuarios añadidos</span>
    <app-users></app-users>
  </div>

  <div class="shadow-2 p-2 mt-2 surface-card border-round-sm">
    <span class="col-12 text-xl pb-0 font-semibold"
      >Últimas empresas añadidas</span
    >
    <app-company-table></app-company-table>
  </div>
</section>
