import { Component, OnInit } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { CompanyEntity } from '../../../domain/entities/company.entity';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Toast } from 'primeng/toast';
import { CompanyPaginationParamsModel } from '../../../data/models/company-pagination-params.model';
import { Failure } from 'src/app/core/utils/failure';
import { CompanyPagination } from '../../../domain/entities/company-pagination';
import { GetAllPaginationCompaniesUseCase } from '../../../domain/usecases/get-all-pagination-companies.usecase';
import { DeleteCompanyModel } from '../../../data/models/delete-company.model';
import { DeleteCompanyUseCase } from '../../../domain/usecases/delete-company.usecase';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class CompanyTableComponent implements OnInit{
  selectedCompany?: CompanyEntity;
  selectedCompanyId?: string;
  defaultRows: number = 5;
  defaultPage: number = 1;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;
  isLoading: boolean = true;
  homeView: boolean = false;
  paginatedData: CompanyPagination | undefined = undefined;
  allCompanies: CompanyEntity[] = [];
  companyList: CompanyEntity[] = [];
  public totalQuantity: number = 0;

  private searchSubject = new Subject<string>();
  searchTerm: string = '';
  filteredCompanyList: CompanyEntity[] = [];
  isFiltered: boolean = false;

  public items: MenuItem[] = [
    {
      label: 'Editar empresa',
      icon: 'pi pi-pencil',
      command: () => this.editCompany(),
    },
    {
      label: 'Eliminar empresa',
      icon: 'pi pi-trash',
      command: () => this.showOptionPopup(),
    },
  ];

  ngOnInit(): void {
    this.getAllCompanies();
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }

    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.filterCompanies();
      });
  }

  constructor(
    private router: Router,
     private messageService: MessageService,
     private confirmationService: ConfirmationService,
     private getAllPaginationCompaniesUseCase: GetAllPaginationCompaniesUseCase,
     private deleteCompanyUseCase: DeleteCompanyUseCase,
    ) { }

    loadRecords(event: TableLazyLoadEvent) {
      this.defaultPage = (event.first || 0) / (event.rows || this.defaultRows) + 1;
      this.itemsPerPage = event.rows || this.defaultRows;
      this.applyPagination();
    }

  async getAllCompanies(): Promise<void> {
    this.isLoading = true;
    let params = new CompanyPaginationParamsModel(1, 1000000);
    let result = await this.getAllPaginationCompaniesUseCase.execute(params);
    if (result instanceof Failure) {
      this.isLoading = false;
      return;
    }
    this.allCompanies = result.response || [];
    this.totalQuantity = result.totalQuantity;
    this.isLoading = false;
    this.applyPagination();
  }

  private filterCompanies(): void {
    if (this.searchTerm.trim() === '') {
      this.resetToInitialData();
    } else {
      const searchTermLower = this.searchTerm.toLowerCase().trim();
      this.filteredCompanyList = this.allCompanies.filter(company =>
        this.matchesSearchTerm(company, searchTermLower)
      );
      this.isFiltered = true;
      this.totalQuantity = this.filteredCompanyList.length;
      this.defaultPage = 1;
      this.first = 0;
      this.applyPaginationToFiltered();
    }
  }

  async getDataPaginated(page: number, quantity: number): Promise<void> {
    let params = new CompanyPaginationParamsModel(page, quantity);
    let result = await this.getAllPaginationCompaniesUseCase.execute(params);
    if (result instanceof Failure) {
      return;
    }
    this.paginatedData = result;
    this.companyList = result.response || [];
    this.filteredCompanyList = [...this.companyList];
    this.totalQuantity = result.totalQuantity;
    this.isFiltered = false;
  }

  editCompany(): void {
    if (this.selectedCompanyId) {
      this.router.navigate(['main/company/update', this.selectedCompanyId]);
    }
  }

  filterClick(): void {
    if (this.searchTerm.trim() === '') {
      this.resetToInitialData();
    } else {
      const searchTermLower = this.searchTerm.toLowerCase().trim();
      this.filteredCompanyList = this.allCompanies.filter(company =>
        this.matchesSearchTerm(company, searchTermLower)
      );
      this.isFiltered = true;
      this.totalQuantity = this.filteredCompanyList.length;
      this.defaultPage = 1;
      this.first = 0;
      this.applyPaginationToFiltered();
    }
  }

  private resetToInitialData(): void {
    this.isFiltered = false;
    this.filteredCompanyList = [];
    this.totalQuantity = this.allCompanies.length;
    this.defaultPage = 1;
    this.first = 0;
    this.applyPagination();
  }

  applyPagination(): void {
    const startIndex = (this.defaultPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.companyList = this.allCompanies.slice(startIndex, endIndex);
  }

  applyPaginationToFiltered(): void {
    const startIndex = (this.defaultPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.companyList = this.filteredCompanyList.slice(startIndex, endIndex);
  }

  private matchesSearchTerm(company: CompanyEntity, searchTerm: string): any {
    return (
      company.razon_social.toLowerCase().includes(searchTerm) ||
      company.cuit.toLowerCase().includes(searchTerm) ||
      company.email.toLowerCase().includes(searchTerm) ||
      company.city.toLowerCase().includes(searchTerm) ||
      (company.phone && company.phone.toLowerCase().includes(searchTerm))
    );
  }

  get displayList(): CompanyEntity[] {
    return this.companyList;
  }

  async deleteCompany(): Promise<void> {
    this.isLoading = true;
    let company = new DeleteCompanyModel(this.selectedCompanyId!);
    let result = await this.deleteCompanyUseCase.execute(company);
    this.isLoading = false;
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Empresa eliminada',
      detail: 'La empresa se eliminó exitosamente.',
    });
    this.getDataPaginated(this.defaultPage, this.defaultRows);

  }

  showOptionPopup() {
    this.confirmationService.confirm({
      header: 'Eliminar empresa',
      message:
        `¿Está seguro de que desea eliminar la empresa? Se perderán todos los datos.`,
      accept: () => {
        this.deleteCompany();
      },
    });
  }

  selectcompany(id: string) {
    this.selectedCompanyId = id;
  }

  onSearchChange(): void {
    this.searchSubject.next(this.searchTerm);
  }

  changePage(event: any) {
    this.first = event.first;
    this.defaultPage = event.page + 1;
    this.itemsPerPage = event.rows;
    if (this.isFiltered) {
      this.applyPaginationToFiltered();
    } else {
      this.applyPagination();
    }
  }
}
