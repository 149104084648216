import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CreateMultiCompanyUseCase } from '../../../domain/usecases/create-multi-company.usecase';
import { MultiCompanyFileModel } from '../../../data/models/multi-company-file.model';
import { Failure } from 'src/app/core/utils/failure';
import { HttpClient } from '@angular/common/http';

type FileType = 'csv' | 'xlsx';

interface Base64File {
  name: string;
  base64: string | ArrayBuffer | null;
  fileType: string;
}

@Component({
  selector: 'app-multi-company',
  templateUrl: './multi-company.component.html',
  styleUrls: ['./multi-company.component.css']
})
export class MultiCompanyComponent {
  @Output() showMessage = new EventEmitter<{ severity: string, summary: string, detail: string }>();

  isDragging: boolean = false;
  progress: number = 0;
  csvFile: any[] = [];
  disableFileImageUpload: boolean = false;
  disableFilePdfUpload: boolean = false;
  selectedFile?: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private createMultiCompanyUseCase: CreateMultiCompanyUseCase,
    private http: HttpClient,
  ) { }

  companiesForm: FormGroup = this.formBuilder.group({
    file: ['', [Validators.required]],
  });

  async createMultiCompany(data: FormGroup, file: File): Promise<void> {
    const formData = new FormData();
      Object.keys(data.controls).forEach(key => {
      const value = data.get(key)?.value;
      formData.append(key, value);
    });

    if (file) {
      formData.append('file', file, file.name);
    }

    let result = await this.createMultiCompanyUseCase.execute(formData);
    if (result instanceof Failure) {
      this.showMessage.emit({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }

    this.showMessage.emit({
      severity: 'success',
      summary: 'Empresas creadas',
      detail: 'Las empresas se crearon exitosamente.',
    });

    setTimeout(() => {
      this.router.navigate(['/main/companies']);
    }, 1100);
  }


  createNewCompanyModel(): MultiCompanyFileModel {
    return new MultiCompanyFileModel(
      this.companiesForm.get('file')?.value,
    )
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any) {
    event.preventDefault();
    this.isDragging = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.csvFile = [files[0]];
      this.updateFormFiles();
      this.checkFileImageCount();
    }
  }

  onFileChange(files: FileList) {
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.csvFile = [files[0]];
      this.updateFormFiles();
      this.checkFileImageCount();
    }
  }

  onChange(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.onFileChange(files);
    }
    event.target.value = '';
  }

  async onSubmit() {
    await this.createMultiCompany(this.companiesForm, this.selectedFile);
  }

  private getFileType(file: File): FileType {
    const extension = file.name.split('.').pop()?.toLowerCase() || '';
    const fileTypes = this.getFileTypes();
    if (fileTypes.has(extension)) {
      return fileTypes.get(extension) as FileType;
    } else {
      throw new Error(`Unsupported file type: ${extension}`);
    }
  }

  getFileTypes(): Map<string, string> {
    return new Map<string, string>([
      ['pdf', 'pdf'],
      ['doc', 'doc'],
      ['docx', 'doc'],
      ['jpg', 'image'],
      ['jpeg', 'image'],
      ['png', 'image'],
      ['gif', 'image'],
      ['bmp', 'image'],
      ['csv', 'spreadsheet'],
      ['xlsx', 'spreadsheet'],
    ]);
  }

  convertToBase64(file: File, fileType: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        const base64File: Base64File = {
          name: file.name,
          base64: base64String,
          fileType,
        };
        this.csvFile.push(base64File);
        this.updateFormFiles();
      }
    };
    reader.readAsDataURL(file);
  }

  updateFormFiles() {
    if (this.csvFile.length > 0) {
      if (this.csvFile[0] instanceof File) {
        this.companiesForm.get("file")?.setValue(this.csvFile[0]);
      } else {
        const fileBase64Array = this.csvFile.map((file) => file.base64);
        this.companiesForm.get("file")?.setValue(fileBase64Array);
      }
      this.companiesForm.get('file')?.updateValueAndValidity();
      this.checkFileImageCount();
    }
  }

  private assignImages(file: any, base64String: string) {
    this.csvFile.push({ name: file.name, base64: base64String });
    const fileDniBase64Array = this.csvFile.map((image) => image.base64);
    this.companiesForm.get("file")?.setValue(fileDniBase64Array);
    this.checkFileImageCount();
  }

  private checkFileImageCount() {
    this.disableFileImageUpload = this.csvFile.length > 0;
  }

  deleteFileImage(index: number) {
    this.csvFile.splice(index, 1);
    const fileDniBase64Array = this.csvFile.map((image) => image.base64);
    this.companiesForm.get("file")?.setValue(fileDniBase64Array.length ? fileDniBase64Array : null);
    this.companiesForm.get("file")?.markAsTouched();
    this.companiesForm.get("file")?.markAsDirty();
    this.checkFileImageCount();
  }

  cancel() {
    this.router.navigate(['/main/companies']);
  }

  downloadCsv() {
    let url = 'assets/files/CargaMasiva.xlsx';
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Plantilla carga empresas.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

}
